import { useState, useEffect } from "react";
import axios from "axios";
import { ROOT_DOMAIN_API } from "../../../Constant";
import "./TextMailSetup.css";
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { toast } from 'react-toastify';

function TextMailSetup(props) {
    const [lang, setLang] = useState([]);
    const [textMail, setTextMail] = useState([]);
    const [textMailDesc, setTextMailDesc] = useState({});
    const [adminList, setAdminList] = useState([]);
    const animatedComponents = makeAnimated();
    const [libelle, setLibelle] = useState("");
    const [activeLang, setActiveLang] = useState(1);

    const { id } = props;

    useEffect(() => {
        const fetchAdminList = async () => {
            try {
                const resp = await axios(ROOT_DOMAIN_API + "/manage/v1/bo_moderator/bo_getAdminList");
                const obj = resp.data;
                if (obj.status && obj.results) {
                    const adminList = obj.results.map((row) => ({
                        value: row.adm_id,
                        label: row.adm_name
                    }));
                    setAdminList(adminList);
                }
            } catch (error) {
                console.error('Error fetching admin list:', error);
                toast.error('Failed to fetch mails', {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        };

        fetchAdminList();
    }, []);

    useEffect(() => {
        const fetchTextMail = async () => {
            try {
                const resp = await axios(ROOT_DOMAIN_API + "/manage/v1/bo_moderator/bo_getTextMailById?id=" + id);
                const obj = resp.data;
                if (obj.status && obj.results) {
                    setTextMail(obj.results);
                    setLibelle(obj.results.libelle);
                }
            } catch (error) {
                console.error('Error fetching text mail:', error);
            }
        };

        if (id) {
            fetchTextMail();
        }
    }, [id]);

    useEffect(() => {
        const fetchTextMailDesc = async () => {
            try {
                const resp = await axios(ROOT_DOMAIN_API + "/manage/v1/bo_moderator/bo_getTextMailDescByTextMailId?id=" + id);
                const obj = resp.data;
                if (obj.status && obj.results) {
                    setLang(obj.results.lang);
                    setTextMailDesc(obj.results.result);
                }
            } catch (error) {
                console.error('Error fetching text mail description:', error);
            }
        };

        if (id) {
            fetchTextMailDesc();
        }
    }, [id]);

    const updateTextMail = async () => {
        try {
            const resp = await axios.post(ROOT_DOMAIN_API + "/manage/v1/bo_moderator/bo_updateTextMail", {
                textMailId: id,
                textMailLibelle: libelle,
                textMailDesc: textMailDesc,
            });
            const obj = resp.data;

            if (obj.status) {
                //navigate("/TextMailList");
                toast.success('Mail updated successfully', {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });


            } else {
                toast.error('Failed to update the mail', {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        } catch (error) {
            console.error('Error updating text mail:', error);
            toast.error('Failed to update the mail', {
                position: "bottom-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    };

    const updateField = (langId, field, content) => {
        setTextMailDesc(prev => ({
            ...prev,
            [langId]: {
                ...prev[langId],
                [field]: content
            }
        }));
    };

    return (
        <div className={`TextMailSetup GridCol1-13 P25 Width100 BoxSizeBorder ${props.childClass}`}>
            <div>
                <div className="FWBold">Mail Name</div>
                <input
                    type="text"
                    name="textMailLibelle"
                    id="textMailLibelle"
                    className="ActionInput P5"
                    value={libelle}
                    onChange={(evt) => setLibelle(evt.target.value)}
                />
                <input type="hidden" name="textMailId" id="textMailId" value={textMail.id} />
            </div>
            <hr />

            {lang.map((row) => (
                <div key={`lang-${row.lang_id}`} className="BorderRad3 ShadowContainer P15 BorderGrey MB10">
                    <div
                        className={`${activeLang === row.lang_id ? "MB10" : ""} FWBold FlexRow JustifyBetween CursorPointer`}
                        onClick={() => setActiveLang(activeLang === row.lang_id ? null : row.lang_id)}
                    >
                        <div>{row.lang_name}</div>
                        <div>
                            <i className={`fa-solid fa-chevron-${activeLang === row.lang_id ? 'up' : 'down'} TGrey`} />
                        </div>
                    </div>
                    {activeLang === row.lang_id && textMailDesc[row.lang_id] && (
                        <div>
                            <div className="MB10">
                                <div className="FWBold">Object</div>
                                <input
                                    type="text"
                                    className="textMailObject ActionInput P5"
                                    value={textMailDesc[row.lang_id].object || ''}
                                    onChange={(evt) => updateField(row.lang_id, 'object', evt.target.value)}
                                />
                            </div>
                            <div className="MB10">
                                <div className="FWBold">Body</div>
                                <textarea
                                    rows="8"
                                    className="textMailBody ActionInput P5"
                                    value={textMailDesc[row.lang_id].body || ''}
                                    onChange={(evt) => updateField(row.lang_id, 'body', evt.target.value)}
                                />
                            </div>
                            <div className="MB10">
                                <div className="FWBold">The Moneytizer Recipient</div>
                                <Select
                                    closeMenuOnSelect={false}
                                    components={animatedComponents}
                                    value={textMailDesc[row.lang_id].admin || []}
                                    isMulti
                                    options={adminList}
                                    onChange={(selectedOptions) => updateField(row.lang_id, 'admin', selectedOptions)}
                                />
                            </div>
                            <div className="MB10">
                                <div className="FWBold">Other Recipient (separate with a ",")</div>
                                <input
                                    type="text"
                                    className="textMailOtherReceiver ActionInput P5"
                                    value={textMailDesc[row.lang_id].other_receiver || ''}
                                    onChange={(evt) => updateField(row.lang_id, 'other_receiver', evt.target.value)}
                                />
                            </div>
                            <div>
                                <div className="FWBold">Reply To</div>
                                <input
                                    type="text"
                                    className="textMailReplyTo ActionInput P5"
                                    value={textMailDesc[row.lang_id].reply_to || ''}
                                    onChange={(evt) => updateField(row.lang_id, 'reply_to', evt.target.value)}
                                />
                            </div>
                        </div>
                    )}
                </div>
            ))}

            <div className="popup-footer Gap10 FlexRow JustifyBetween">
                <button
                    type="button"
                    onClick={updateTextMail}
                    className="ActionButton BGreen"
                >
                    Validate
                </button>
                <button
                    className="ActionButton BBlack TWhite"
                    onClick={props.togglePopup}
                >
                    Close
                </button>
            </div>
        </div>
    );
}

export default TextMailSetup;