import { useState, useEffect, useRef } from "react";
import axios from "axios";
import { ROOT_DOMAIN_API } from "../../Constant";
import "./GuideList.css";
import { Link } from "react-router-dom";

function GuideList(props) {
    const [guideList, setGuideList] = useState([]);
    const [lang, setLang] = useState([]);
    const [langSelected, setLangSelected] = useState(1);

    useEffect(() => {
        (async () => {
            try {
                const resp = await axios(ROOT_DOMAIN_API + "/manage/v1/bo_moderator/bo_guideList?lang_id=" + langSelected);
                const obj = await resp.data;
                if (obj.status && obj.results) {
                    setGuideList(obj.results.guideList);
                    setLang(obj.results.lang);
                }
            } catch (error) {
                console.error(error);
            }
        })();
    }, [langSelected]);

    const updateGuideDescriptionStatus = (id, status) => {
        (async () => {
            const resp = await axios.post(ROOT_DOMAIN_API + '/manage/v1/bo_moderator/bo_updateGuideDescriptionStatus',
                { id: id, status: status, lang_id: langSelected });
            const obj = await resp.data;
            if (obj.status) {
                setGuideList(obj.results);
            }
        })();
    }

    const dragGuideList = useRef(0);
    const draggedOverGuideList = useRef(0);

    function handleSort() {
        console.log(dragGuideList.current);
        console.log(draggedOverGuideList.current);

        if (dragGuideList.current === draggedOverGuideList.current) {
            return false;
        }

        const guideListClone = [...guideList];

        if (dragGuideList.current < draggedOverGuideList.current) {
            const newGuideList = [];
            guideList.forEach((guide, i) => {
                if (i < dragGuideList.current) {
                    guide.rank = i;
                    newGuideList.push(guide);
                } else if (i > dragGuideList.current && i < draggedOverGuideList.current) {
                    guide.rank = i;
                    newGuideList.push(guide);
                } else if (i === draggedOverGuideList.current) {
                    guide.rank = i;
                    newGuideList.push(guide);
                    guideListClone[dragGuideList.current].rank = i + 1;
                    newGuideList.push(guideListClone[dragGuideList.current]);
                } else if (i > draggedOverGuideList.current) {
                    guide.rank = i + 1;
                    newGuideList.push(guide);
                }
            });

            console.log(newGuideList);

            setGuideList(newGuideList);
        } else {
            const newGuideList = [];
            guideList.forEach((guide, i) => {
                if (i < draggedOverGuideList.current) {
                    guide.rank = i;
                    newGuideList.push(guide);
                } else if (i === draggedOverGuideList.current) {
                    guideListClone[dragGuideList.current].rank = i;
                    newGuideList.push(guideListClone[dragGuideList.current]);
                    guide.rank = i + 1;
                    newGuideList.push(guide);
                } else if (i > draggedOverGuideList.current && i !== dragGuideList.current) {
                    guide.rank = i + 1;
                    newGuideList.push(guide);
                }
            });

            setGuideList(newGuideList);
        }

        (async () => {
            const resp = await axios.post(ROOT_DOMAIN_API + '/manage/v1/bo_moderator/bo_updateGuideDescriptionRank',
                { guideList: guideList, lang_id: langSelected });
            const obj = await resp.data;
            if (obj.status) {
                setGuideList(obj.results);
            }
        })();
    }

    return (
        <div className={"GuideList GridCol1-13 ShadowContainer P25 BWhite Width100 BoxSizeBorder " + props.childClass}>
            <div className="TRight MB20">
                <select className="ActionInput P5"
                    name="lang"
                    onChange={(e) => setLangSelected(e.target.value)}
                >
                    {lang.map((item) => (
                        <option value={item.lang_id} selected={item.lang_id === 1}>
                            {item.lang_name}
                        </option>
                    ))}
                </select>
                <Link to={"/guideSetup/0"} className="BGreen TWhite ActionButton ML5 NoUnderline">
                    + Add Guide
                </Link>
            </div>
            <table className='Width100'>
                <thead>
                    <tr className="BRed TWhite">
                        <th></th>
                        <th className='P5'>Category Name</th>
                        <th className='P5'>Name</th>
                        <th className='P5'>Description</th>
                        <th className='P5'>Link</th>
                        <th className='P5'>Status</th>
                        <th className='P5'>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {guideList.map((row, key) => {
                        return <tr
                            draggable
                            onDragStart={() => (dragGuideList.current = key)}
                            onDragEnter={() => (draggedOverGuideList.current = key)}
                            onDragEnd={handleSort}
                            onDragOver={(e) => e.preventDefault()}
                        >
                            <td className="P5">
                                <i className="fa-solid fa-arrows-up-down-left-right" style={{ fontSize: '20px' }}></i>
                            </td>
                            <td className="P5">{row.category_name}</td>
                            <td className="P5">{row.name}</td>
                            <td className="P5">{row.description}</td>
                            <td className="P5">{row.link}</td>
                            <td className="P5">
                                <label className="switch">
                                    <input type="checkbox"
                                        onClick={() => updateGuideDescriptionStatus(row.id, row.status === 1 ? 0 : 1)}
                                        checked={row.status === 1}
                                    />
                                    <span className="slider round"></span>
                                </label>
                            </td>
                            <td className="P5">
                                <div className="FlexRow">
                                    <Link to={"/guideSetup/" + row.guide_id}
                                        className="BOrange TWhite ActionButton ML5">
                                        <i className="fa-solid fa-pen"></i> Edit
                                    </Link>
                                </div>
                            </td>
                        </tr>
                    })}
                </tbody>
            </table>
        </div>
    );
}

export default GuideList;