import LateralNavComponent from "../../Components/LateralNavComponent/LateralNavComponent";
import './PerfPartners.css';
import { useState, useEffect } from "react";
import HeadLine from "../../Components/HeadLine/HeadLine";
import { ToastContainer } from "react-toastify";
import AuthComponent from "../../Components/AuthComponent/AuthComponent";
import axios from "axios";
import { ROOT_DOMAIN_API } from "../../Constant";
import { DayPicker } from 'react-day-picker';
import { format } from "date-fns";

function PerfPartners() {
    const pageTab = "perf_partners";
    const [cron_ssp, setCronSsp] = useState([]);
    const [date, setDate] = useState([]);
    const [total_by_day, setTotalByDay] = useState([]);

    const defaultStartDate = new Date();
    defaultStartDate.setDate(defaultStartDate.getDate() - 10);
    const defaultEndDate = new Date();
    defaultEndDate.setDate(defaultEndDate.getDate() - 1);
    
    const [dateRange, setDateRange] = useState({
        from: defaultStartDate,
        to: defaultEndDate
    });
    const [tempDateRange, setTempDateRange] = useState({
        from: defaultStartDate,
        to: defaultEndDate
    });
    const [showDatePicker, setShowDatePicker] = useState(false);

    const formatDateStr = (date) => {
        return format(date, 'yyyy-MM-dd');
    };

    const toggleDatePicker = () => {
        setShowDatePicker(!showDatePicker);
        setTempDateRange(dateRange);
    };

    const handleRangeSelect = (range) => {
        if (range?.from) {
            setTempDateRange({ 
                from: range.from, 
                to: range.to || tempDateRange.to 
            });
        }
    };

    const applyDateRange = () => {
        setDateRange(tempDateRange);
        setShowDatePicker(false);
    };

    useEffect(() => {
        (async () => {
            try {
                const resp = await axios.get(
                    `${ROOT_DOMAIN_API}/manage/v1/bo_user/bo_partnersPerformance?` +
                    `date_from=${formatDateStr(dateRange.from)}&` +
                    `date_to=${formatDateStr(dateRange.to)}`
                );
                
                const obj = resp.data;
                if (obj.status && obj.results) {
                    setCronSsp(obj.results.cron_ssp);
                    setDate(obj.results.date);
                    setTotalByDay(obj.results.total_by_day);
                }
            } catch (error) {
                console.error(error);
            }
        })();
    }, [dateRange]);

    return (
        <div className="PerfPartners GridTemplate12">
            <AuthComponent />
            <ToastContainer
                theme="colored"
                position="bottom-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <LateralNavComponent pageTab={pageTab} />
            <HeadLine childClass="Headline GridCol1-13" title="Performance Partners" />
            <div className="ShadowContainer BWhite GridCol1-13 P25">
                <h2>Performance Partners</h2>

                <div className="FlexRow MB20">
                    <div className="FlexCol">
                        <button className="ActionButton BRed" onClick={toggleDatePicker}>
                            <i className="fa-solid fa-calendar"></i>
                            &nbsp;{formatDateStr(dateRange.from)} - {formatDateStr(dateRange.to)}
                        </button>
                        {showDatePicker && (
                            <div className="DayPickerContainer">
                                <DayPicker
                                    mode="range"
                                    defaultMonth={tempDateRange.from}
                                    selected={tempDateRange}
                                    onSelect={handleRangeSelect}
                                    className="DayPickerObject"
                                />
                                <div className="FlexRow JustifyEnd P10">
                                    <button 
                                        className="ActionButton BRed"
                                        onClick={applyDateRange}
                                    >
                                        Appliquer
                                    </button>
                                </div>
                            </div>
                        )}
                    </div>
                </div>

                <table className="PartnersPerfTable">
                    <thead>
                        <tr>
                            <th></th>
                            {date.length > 0 && date.map((row, key) => (
                                <th key={key}>{row.date}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {cron_ssp.length > 0 && cron_ssp.map((row, rowIndex) => (
                            <tr key={rowIndex}>
                                {row.length > 0 && row.map((row2, key2) => (
                                    <td 
                                        key={key2}
                                        className={`P5 ${
                                            row2.total === 0 
                                                ? 'no_total' 
                                                : row2.pourcentage >= 100 
                                                    ? 'more_100_percent' 
                                                    : row2.pourcentage <= -40 
                                                        ? 'less_40_percent' 
                                                        : ''
                                        }`}
                                    >
                                        {!isNaN(row2.total) 
                                            ? (row2.total > 0 
                                                ? `${row2.total}${(row2.pourcentage <= -40 || row2.pourcentage >= 100) 
                                                    ? ` (${row2.pourcentage}%)` 
                                                    : ''}`
                                                : "") 
                                            : row2.total}
                                    </td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                    <tfoot>
                        <tr>
                            <td></td>
                            {total_by_day.length > 0 && total_by_day.map((row, key) => (
                                <td key={key} className="FWBold">{row.total}</td>
                            ))}
                        </tr>
                    </tfoot>
                </table>
            </div>
        </div>
    );
}

export default PerfPartners;