import './TextMailRow.css';
import { useState } from 'react';
import TextMailSetup from '../TextMailSetup/TextMailSetup';

function TextMailRow(props) {
    const [isPopupOpen, setIsPopupOpen] = useState(false);

    const togglePopup = () => {
        setIsPopupOpen(!isPopupOpen);
    };

    return (
        <>
            <tr className="TextMailRow">
                <td className='P5'>{props.id}</td>
                <td className='P5'>
                    {props.libelle}
                </td>
                <td className='P5 FlexRow JustifyCenter Gap10'>
                    <button 
                        className="BOrange TWhite ActionButton ML5"
                        onClick={togglePopup}
                    >
                        <i className="fa-solid fa-pen"></i> Edit
                    </button>
                    <label className="switch">
                        <input type="checkbox"
                            onClick={() => props.updateTextMailStatus(props.id, props.status === 1 ? 0 : 1)}
                            checked={props.status === 1}
                        />
                        <span className="slider round"></span>
                    </label>
                </td>
            </tr>

            {isPopupOpen && (
                <div>
                    <div className='overlay'></div>
                    <div className="popup">
                        <div className='popup-content-wrapper'>
                            <TextMailSetup
                                id={props.id}
                                onSuccess={togglePopup}
                                togglePopup={togglePopup}
                            />
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

export default TextMailRow;