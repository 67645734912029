import './AcceptedSitesRow.css';
import 'react-tooltip/dist/react-tooltip.css'
import { Link } from 'react-router-dom';
import { ROOT_DOMAIN_API } from '../../../Constant';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';

function AcceptedSitesRow(props) {
    const [siteIDMoneybox, setSiteIDMoneybox] = useState("");

    useEffect(() => {
        setSiteIDMoneybox(props.siteId);
    }, [props.siteId]);

    const handleMoneyboxAccess = async () => {
        try {
            const response = await axios.get(`${ROOT_DOMAIN_API}/manage/v1/bo_user/bo_moneyboxToken`, {
                params: { site_id: siteIDMoneybox },
            });

            if (response.data.status && response.data.results) {
                let url = "https://www.themoneytizer.com/new_manager/login&site_id=" + siteIDMoneybox + "&tbm_hash=" + response.data.results.tbm_hash
                window.open(url.toString(), '_blank');
            } else {
                toast.error('Failed to connect to Moneybox ', {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        } catch (error) {
            console.error("Error adding site:", error);
            toast.error('Error adding site', {
                position: "bottom-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    };

    return (
        <tr className={"AcceptedSitesRow VAlignTop " + (props.siteFraud === 1 || props.userFraud === 1 ? "Fraud " : "") + (props.userSuspended === 1 ? "Suspended " : "")}>
            <td className='P5'>
                <div className='FlexCol'>
                    <div className='FlexRow FlexWrap Gap15'>
                        <div className="BorderRad3 FlexRow AlignCenter JustifyCenter Width10">
                            <i className={"fa-solid fa-xmark TRed " + (props.country ? 'Hide' : '')}></i>
                            <img
                                className={props.country ? 'Flag' : 'Hide'}
                                src={"https://flagcdn.com/w40/" + (props.country ? props.country.toLowerCase() : '') + ".png"}
                                srcSet={"https://flagcdn.com/w80/" + (props.country ? props.country.toLowerCase() : '') + ".png 2x"}
                                height={"25"}
                                alt={props.country} />
                        </div>
                        <div className={"BorderRad3 P5 FlexRow AlignCenter JustifyCenter WidthMaxContent TWhite Height25px " + (props.siteChild === 1 ? "BGreen" : "BPink")}>
                            <p className='M0'>{props.siteChild === 1 ? "Child" : "Parent"}</p>
                        </div>
                        <div className={"BorderRad3 P5 FlexRow AlignCenter JustifyCenter WidthMaxContent TWhite Height25px " + (props.userPremium ? "BYellow" : "Hide")}>
                            <p className='M0'>{props.userPremium ? "Premium" : ""}</p>
                        </div>
                        <div className='FlexRow AlignCenter'>
                            <div className="BorderRad3 BOrange P5 FlexRow AlignCenter JustifyCenter WidthMaxContent TWhite Height25px">
                                <i className={"fa-solid fa-file MR5"}></i>Site ID : {props.siteId}
                            </div>
                        </div>
                        <div className='FlexRow AlignCenter'>
                            <div className="BorderRad3 BBlue P5 FlexRow AlignCenter JustifyCenter WidthMaxContent TWhite Height25px">
                                <i className={"fa-solid fa-person MR5"}></i>User ID : {props.userId}
                            </div>
                        </div>
                        <div className='FlexRow AlignCenter'>
                            <div className="BorderRad3 BBlue P5 FlexRow AlignCenter JustifyCenter WidthMaxContent TWhite Height25px">
                                {props.userFirstname} {props.userLastname}
                            </div>
                        </div>
                        <div className="BorderRad3 BDarkSaphir P5 FlexRow AlignCenter JustifyCenter WidthMaxContent TWhite Height25px">
                            <i className={"fa-solid fa-align-left"}></i>
                            <p className='M0 LightText ML5'>Ads.txt : {props.adsTxt}</p>
                        </div>
                        {props.siteCmp !== 0 &&
                        <div className="BorderRad3 BSoftPink P5 FlexRow AlignCenter JustifyCenter WidthMaxContent TWhite Height25px">
                            <i className={"fa-solid fa-circle-question"}></i>
                            <p className='M0 LightText ML5'>{props.siteCmp === 1 ? 'TCF v1' : props.siteCmp === 2 ? 'TCF v2' : props.siteCmp === -1 ? 'TCF NEVER' : ''}</p>
                        </div>
                        }
                        <div className="BorderRad3 BPixelatedGrass P5 FlexRow AlignCenter JustifyCenter WidthMaxContent TWhite Height25px">
                            <i className={"fa-solid fa-tag"}></i>
                            <p className='M0 LightText ML5'>Theme : {props.theme}</p>
                        </div>
                        <div className="BorderRad3 BLightGrey P5 FlexRow AlignCenter JustifyCenter WidthMaxContent TWhite Height25px">
                            <i className={"fa-solid fa-envelope"}></i>
                            <p className='M0 LightText ML5'>{props.userMail}</p>
                        </div>
                        <div className={"BorderRad3 BRed P5 FlexRow AlignCenter JustifyCenter WidthMaxContent TWhite Height25px " + (props.raisonRefus === "adcash" ? "" : "Hide")}>
                            <i className={"fa-solid fa-tag"}></i>
                            <p className='M0 LightText ML5'>{props.raisonRefus === "adcash" ? "Adcash" : ""}</p>
                        </div>
                        <div className={"BorderRad3 BPurple P5 FlexRow AlignCenter JustifyCenter WidthMaxContent TWhite Height25px " + (props.sales ? "" : "Hide")}>
                            <i className={"fa-solid fa-rocket"}></i>
                            <p className='M0 LightText ML5'>{props.sales}</p>
                        </div>
                    </div>
                </div>
            </td>
            <td className='P15'>
                <div className="FlexRow">
                    {props.siteActif === 1 ? <div className='BlobGreen'></div> : <div className='BlobRed'></div>}

                    <a target="_blank" rel="noreferrer" href={props.siteUrl} className="ActionLink">{props.siteUrl}</a>
                </div>
            </td>
            <td className='P15'>
                <p className='M0 Capitalize'>
                    {props.refusedDate ? props.refusedDate : props.validationDate}<br />
                    {props.refusedAuthor ? props.refusedAuthor : props.validationAuthor}
                </p>
            </td>
            <td className='P15'>
                <div className='FlexRow'>
                    <button className="BGreen TWhite ActionButton" onClick={handleMoneyboxAccess}><i className="fa-solid fa-piggy-bank"></i></button>
                    <Link className="BBlue TWhite ActionButton ML5" to={"/statistics/" + props.siteId}><i className="fa-solid fa-chart-simple"></i></Link>
                    <Link className="BOrange TWhite ActionButton ML5" to={"/options/" + props.siteId}><i className="fa-solid fa-gear"></i></Link>
                    <button className="BDarkSaphir TWhite ActionButton ML5" onClick={() => { window.open(("https://crm.themoneytizer.com/crm_user/" + props.userId), "_blank") }}><i className="fa-solid fa-database"></i></button>
                </div>
            </td>
        </tr>
    );
}

export default AcceptedSitesRow;
