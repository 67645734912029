import { useState, useEffect } from "react";
import axios from "axios";
import { ROOT_DOMAIN_API } from "../../Constant";
import "./NotificationList.css";
import { Link } from "react-router-dom";

function NotificationList(props) {
    const [notificationList, setNotificationList] = useState([]);

    useEffect(() => {
        (async () => {
            try {
                const resp = await axios(ROOT_DOMAIN_API + "/manage/v1/bo_moderator/bo_getNotificationHistory");
                const obj = await resp.data;
                if (obj.status && obj.results) {
                    setNotificationList(obj.results);
                }
            } catch (error) {
                console.error(error);
            }
        })();
    }, []);

    return (
        <div className={"NotificationList GridCol1-13 ShadowContainer P25 BWhite Width100 BoxSizeBorder " + props.childClass}>
            <div className="TRight MB20">
                <Link to={"/notificationSetup"} className="BGreen TWhite ActionButton ML5 NoUnderline">
                    Send Notification
                </Link>
            </div>
            <table className='Width100 TCenter'>
                <thead>
                    <tr className="BRed TWhite">
                        <th className='P5' style={{ width: '10%' }}>Number of notification</th>
                        <th className='P5' style={{ width: '60%' }}>Notification message</th>
                        <th className='P5' style={{ width: '10%' }}>Sender</th>
                        <th className='P5' style={{ width: '15%' }}>Date</th>
                        <th className='P5' style={{ width: '5%' }}>No email ?</th>
                    </tr>
                </thead>
                <tbody>
                    {notificationList.map((row, key) => {
                        return <tr>
                            <td className='P15'>{row.notification_message_count}</td>
                            <td className='P15'>{row.notification_message_modified}</td>
                            <td className='P15'>{row.adm_name}</td>
                            <td className='P15'>{row.notification_date}</td>
                            <td className='P15'>{row.notification_no_email}</td>
                        </tr>
                    })}
                </tbody>
            </table>
        </div>
    );
}

export default NotificationList;