import { useState, useEffect } from "react";
import axios from "axios";
import { ROOT_DOMAIN_API } from "../../Constant";
import "./RevenuesPartner.css";
import { BarLoader } from "react-spinners";

function RevenuesPartner(props) {
    const [partnersData, setPartnersData] = useState([]);

    const downloadPerfPartners = function(){
        if(partnersData.length === 0){
            return;
        }

        console.log(partnersData);
        const headers = ['Partner Name', 'Revenues'];

        const csvRows = [];
        csvRows.push(headers.join(','));
        
        let i = 0;

        partnersData.forEach(row => {
            i += parseFloat(row.revenues);
            const values = [row.partner_name_display, row.revenues];
            csvRows.push(values.join(','));
        });
        
        const values = ["total", i];
        csvRows.push(values.join(','));

        const csvContent = csvRows.join('\n');
        const blob = new Blob([csvContent], { type: 'text/csv' });
        
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = 'partners_data.csv';
        
        link.click();
    }

    useEffect(() => {
        (async () => {
            try {
                const resp = await axios.get(`${ROOT_DOMAIN_API}/manage/v1/bo_user/bo_getRevenuesPartner`, {
                    params: {
                        date_from: props.sDate,
                        date_to: props.eDate,
                    }
                });
                const obj = await resp.data;
                if (obj.status && obj.results) {
                    setPartnersData(obj.results);
                }
            } catch (error) {
                console.error(error);
            }
        })();
    }, [props.sDate, props.eDate]);

    return (
        <div className="RevenuesPartner ShadowContainer BWhite GridCol1-7 P15">
            <div className="FlexRow JustifyBetween">
                <h2 className="M0 MT5 MB5">Performance Partners</h2>
                <button className="ActionButton BRed TWhite" onClick={() => {downloadPerfPartners()}}>
                    <i className="fa-solid fa-download"></i>
                </button>
            </div>
            
            <div className="RevenuesPartnerContainer">
                <table className="Width100">
                    <thead>
                        <tr className="BRed TWhite">
                            <th>Partner</th>
                            <th>Revenues</th>
                        </tr>
                    </thead>
                    <tbody>
                        {partnersData.length ? (
                            partnersData.map((partner, index) => (
                                <tr key={index}>
                                    <td className="P5">{partner.partner_name_display}</td>
                                    <td className="P5 TCenter">{parseFloat(partner.revenues)} €</td>
                                </tr>
                            ))
                        ) : <></>}
                    </tbody>
                </table>
                {!partnersData.length ? <div className="FlexRow AlignCenter JustifyCenter MT20"><BarLoader height={8} width={250} color="#f41127" /></div> : <></>}
            </div>
        </div>
    );
}

export default RevenuesPartner;
