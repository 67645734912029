import './AdReportUserRow.css';
import { useState } from "react";
import { toast } from 'react-toastify';
import axios from "axios";
import { ROOT_DOMAIN_API } from '../../../Constant';

function AdReportUserRow(props) {
    const [isAuthorized, setAuthorized] = useState(props.authorized === 1);
    const [isDeletedUser, setDeletedUser] = useState(false);

    const handleCopyClick = (contentToCopy) => {
        navigator.clipboard.writeText(contentToCopy)
            .then(() => {
                toast.success("Copied");
            })
            .catch(err => {
                console.error(err);
            });
    };

    const updateAuthorization = (uarId, authorization) => {
        (async () => {
            const resp = await axios.post(ROOT_DOMAIN_API + '/manage/v1/bo_moderator/updateAdReportUserAuthorization',
                { uar_id: parseInt(uarId), authorization: authorization });
            const obj = await resp.data;
            if (obj.status) {
                const isAuth = obj.results.uar_authorized === 1;
                setAuthorized(isAuth);

                toast.success('User authorization updated', {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            } else {
                toast.error('Something went wrong, please try again.', {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        })();
    }

    const deleteUser = (uarId) => {
        (async () => {
            const resp = await axios.delete(ROOT_DOMAIN_API + '/manage/v1/bo_moderator/adReportUser',
                { params: { uar_id: parseInt(uarId) } });
            const obj = await resp.data;
            if (obj.status) {
                setDeletedUser(true);

                toast.success('User deleted', {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            } else {
                toast.error('Something went wrong, please try again.', {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        })();
    }

    return (
        <tr className={"VAlignTop " + (isDeletedUser ? 'Hide' : '')}>
            <td className='P15'>
                <div className='FlexCol'>
                    <div className='FlexRow WrapContent Gap10 JustifyCenter'>
                        <div className="BorderRad3 BOrange P5 FlexRow AlignCenter JustifyCenter WidthFitContent WidthPx75 TWhite Height25px">
                            <i className={"fa-solid fa-person"}></i>
                            <p className='M0 LightText ML5'>{props.userId}</p>
                        </div>
                    </div>
                </div>
            </td>
            <td className='P15'>
                <div className='FlexCol'>
                    <div className='FlexRow JustifyCenter'>
                        <p>{props.name}</p>
                    </div>
                </div>
            </td>
            <td className='P15'>
                <div className='FlexCol'>
                    <div className='FlexRow JustifyCenter'>
                        <p>{props.email}</p>
                    </div>
                </div>
            </td>
            <td className='P15'>
                <div className='FlexCol'>
                    <div className='FlexRow AlignCenter'>
                        <p> {props.token} </p>
                        <i className="fa-solid fa-clipboard ActionButton BPink ML5 HeightFitContent" onClick={() => handleCopyClick(props.token)}></i>
                    </div>
                </div>
            </td>
            <td className='P15'>
                <div className='FlexCol'>
                    <div className='FlexRow JustifyCenter'>
                        <div className="BorderRad3 BBlue P5 FlexRow AlignCenter JustifyCenter TWhite Height35px">
                            <i className={"fa-solid fa-calendar-days"}></i>
                            <p className='M0 LightText ML5'>{props.date}</p>
                        </div>
                    </div>
                </div>

            </td>
            <td className='P15'>
                <div className='FlexCol AlignCenter'>
                    {isAuthorized ?
                        <div className="BorderRad3 BPixelatedGrass P5 FlexRow AlignCenter JustifyCenter WidthFitContent TWhite Height25px">
                            <i className="fa-solid fa-circle-check"></i>
                            <p className='M0 LightText ML5'>Authorized</p>
                        </div>
                        :
                        <div className="BorderRad3 BRed P5 FlexRow AlignCenter JustifyCenter WidthFitContent TWhite Height25px">
                            <i className="fa-solid fa-circle-xmark"></i>
                            <p className='M0 LightText ML5'>Not Authorized</p>
                        </div>
                    }
                </div>
            </td>

            <td className='P15'>
                <div className='FlexCol'>
                    <div className='FlexRow WrapContent Gap10'>
                        {isAuthorized ?
                            <button className='BRed TWhite ActionButton' onClick={() => updateAuthorization(props.uarId, 0)} ><i className="fa-solid fa-lock"></i> Revoke Access</button>
                            :
                            <button className='BPixelatedGrass TWhite ActionButton' onClick={() => updateAuthorization(props.uarId, 1)} ><i className="fa-solid fa-lock-open"></i> Authorize Access</button>
                        }
                        <button className='BRed TWhite ActionButton' onClick={() => deleteUser(props.uarId)} ><i className="fa-solid fa-trash"></i> Delete User</button>

                    </div>
                </div>
            </td>

        </tr >
    );
}

export default AdReportUserRow;
