import './BusinessFinderRow.css';
// import { useState } from "react";
import { toast } from 'react-toastify';
import axios from "axios";
import { ROOT_DOMAIN_API } from '../../../Constant';
import { useState } from 'react';
import BusinessFinderSetup from '../BusinessFinderSetup/BusinessFinderSetup';


function BusinessFinderRow(props) {
    const [rowData, setRowData] = useState({
        userId: props.userId,
        userFirstname: props.userFirstname,
        userLastname: props.userLastname,
        userMail: props.userMail,
        userCurrency: props.userCurrency,
        ibRevshare: props.ibRevshare,
        bfStartDate: props.bfStartDate,
        bfEndDate: props.bfEndDate,
        bfNbMonth: props.bfNbMonth
    });
    const [bfStatus, setBfStatus] = useState(props.bfStatus);
    const [isPopupOpen, setIsPopupOpen] = useState(false);

    const togglePopup = () => {
        setIsPopupOpen(!isPopupOpen);
    };

    const handleSuccess = (updatedData) => {
        setRowData(prevData => ({
            ...prevData,
            ...updatedData
        }));
        props.onUpdate(updatedData);
        props.fetchData();
        togglePopup();
    };

    const updateBusinessFinderStatus = (id, status) => {
        (async () => {
            const resp = await axios.post(ROOT_DOMAIN_API + '/manage/v1/bo_moderator/bo_updateBusinessFinderStatus',
                { id: id, status: status });
            const obj = await resp.data;
            if (obj.status) {
                setBfStatus(status);
                toast.success('Status updated', {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        })();
    }

    const handleMoneyboxAccess = async (user_id) => {
        try {
            const response = await axios.get(`${ROOT_DOMAIN_API}/manage/v1/bo_user/bo_moneyboxTokenUser`, {
                params: { user_id: user_id },
            });

            if (response.data.status && response.data.results) {
                let url = "https://www.themoneytizer.com/new_manager/login&user_id=" + user_id + "&tbm_hash=" + response.data.results.tbm_hash;

                window.open(url.toString(), '_blank');
            } else {
                toast.error('Failed to connect to Moneybox ', {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        } catch (error) {
            console.error("Error connecting to moneybox:", error);
            toast.error('Error connecting to moneybox', {
                position: "bottom-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    };

    return (
        <>
            <tr>
                <td className="P5">{rowData.userId}</td>
                <td className="P5">{rowData.userFirstname}</td>
                <td className="P5">{rowData.userLastname}</td>
                <td className="P5">{rowData.userMail}</td>
                <td className="P5">{rowData.userCurrency}</td>
                <td className="P5">{rowData.ibRevshare}%</td>
                <td className="P5">{rowData.bfStartDate}</td>
                <td className="P5">{rowData.bfEndDate}</td>
                <td className="P5">{rowData.bfNbMonth}</td>
                <td className="P5">
                    <label className="switch">
                        <input type="checkbox"
                            onClick={() => updateBusinessFinderStatus(rowData.bfId, rowData.bfStatus === 1 ? 0 : 1)}
                            checked={bfStatus}
                        />
                        <span className="slider round"></span>
                    </label>
                </td>
                <td className="P5">
                    <div className="FlexRow">
                        <button className="BOrange TWhite ActionButton" onClick={togglePopup}>
                            <i className="fa-solid fa-pen"></i> Edit
                        </button>
                        <button className="BGreen TWhite ActionButton ML5" onClick={() => handleMoneyboxAccess(rowData.userId)}>
                            <i className="fa-solid fa-piggy-bank"></i>
                        </button>
                    </div>
                </td>
            </tr>
            {isPopupOpen && (
                <div>
                    <div className='overlay'></div>
                    <div className="popup">
                        <div className='popup-content-wrapper'>
                            <BusinessFinderSetup
                                bfId={props.bfId}
                                onSuccess={handleSuccess}
                                togglePopup={togglePopup}
                            />
                        </div>
                    </div>
                </div>
            )}
        </>

    );
}

export default BusinessFinderRow;
