import { useState, useEffect } from "react";
import axios from "axios";
import { ROOT_DOMAIN_API } from "../../../Constant";
import "./BusinessFinderSetup.css";
import AsyncSelect from 'react-select/async';
import { toast } from "react-toastify";

function BusinessFinderSetup(props) {
    const [formData, setFormData] = useState({
        userId: 0,
        lastname: "",
        firstname: "",
        email: "",
        revshare: "",
        startDate: "",
        endDate: "",
        nbMonth: "",
        currency: "",
        site: []
    });

    const id = props.bfId;

    useEffect(() => {
        if (id === "0") return;

        (async () => {
            try {
                const resp = await axios.get(`${ROOT_DOMAIN_API}/manage/v1/bo_moderator/bo_getBusinessFinderById`, { params: { id } });
                const obj = resp.data;
                if (obj.status && obj.results) {
                    const { bf_user_id, user_lastname, user_firstname, user_mail, ib_revshare, bf_start_date, bf_end_date, bf_nb_month, user_currency, siteList } = obj.results;
                    setFormData({
                        userId: bf_user_id,
                        lastname: user_lastname,
                        firstname: user_firstname,
                        email: user_mail,
                        revshare: ib_revshare,
                        startDate: bf_start_date,
                        endDate: bf_end_date,
                        nbMonth: bf_nb_month,
                        currency: user_currency,
                        site: siteList
                    });
                }
            } catch (error) {
                console.error(error);
            }
        })();
    }, [id]);

    const loadOptions = async (inputValue) => {
        if (!inputValue) return null;
        const res = await axios.get(`${ROOT_DOMAIN_API}/manage/v1/bo_moderator/bo_getSiteList`, { params: { q: inputValue } });
        return res.data.results;
    };

    const handleInputChange = (field, value) => {
        setFormData(prevState => ({ ...prevState, [field]: value }));
    };

    const handleSelection = (value) => {
        handleInputChange("site", value);
    };

    const updateBusinessFinder = async () => {
        try {
            const resp = await axios.post(`${ROOT_DOMAIN_API}/manage/v1/bo_moderator/bo_updateBusinessFinder`, {
                BusinessFinderId: id,
                BusinessFinderUserId: formData.userId,
                BusinessFinderFirstname: formData.firstname,
                BusinessFinderLastname: formData.lastname,
                BusinessFinderEmail: formData.email,
                BusinessFinderRevshare: formData.revshare,
                BusinessFinderStartDate: formData.startDate,
                BusinessFinderEndDate: formData.endDate,
                BusinessFinderNbMonth: formData.nbMonth,
                BusinessFinderCurrency: formData.currency,
                BusinessFinderSite: formData.site
            });
    
            const obj = resp.data;
    
            if (obj.status) {
                toast.success("Business Finder updated", {
                    position: "bottom-right",
                    autoClose: 3000
                });
                
                if (props.onSuccess) {
                    props.onSuccess({
                        user_id: formData.userId,
                        user_firstname: formData.firstname,
                        user_lastname: formData.lastname,
                        user_mail: formData.email,
                        user_currency: formData.currency,
                        ib_revshare: formData.revshare,
                        bf_start_date: formData.startDate,
                        bf_end_date: formData.endDate,
                        bf_nb_month: formData.nbMonth,
                        bf_id: id
                    });
                }
            } else {
                toast.error(obj.error, {
                    position: "bottom-right",
                    autoClose: 3000
                });
            }
        } catch (error) {
            console.error(error);
            toast.error("Error", {
                position: "bottom-right",
                autoClose: 3000
            });
        }
    };

    return (
        <div className={"BusinessFinderSetup FlexCol P25"}>
            <div className="TCenter Width100 FWBold MB10">{!id ? "Add Business Finder" : `Edit ${formData.firstname} ${formData.lastname}`}</div>
            <div className="FlexRow Gap20 MB20">
                <div className={"Width50"}>
                    <input type="hidden" name="BusinessFinderId" value={id} />

                    {id !== "0" && (
                        <div className="MB10 FlexCol">
                            <div>User ID</div>
                            <input
                                type="text"
                                className="ActionInput P5"
                                value={formData.userId}
                                onChange={(e) => handleInputChange("userId", e.target.value)}
                            />
                        </div>
                    )}

                    {[
                        { label: "Lastname", field: "lastname" },
                        { label: "Firstname", field: "firstname" },
                        { label: "Email", field: "email" },
                        { label: "Revshare", field: "revshare" },
                        { label: "Contract Start Date", field: "startDate" },
                        { label: "Contract End Date", field: "endDate" },
                        { label: "Contract Duration (in month)", field: "nbMonth" }
                    ].map(({ label, field }) => (
                        <div key={field} className="MB10 FlexCol">
                            <div>{label}</div>
                            <input
                                type="text"
                                className="ActionInput P5"
                                value={formData[field]}
                                onChange={(e) => handleInputChange(field, e.target.value)}
                            />
                        </div>
                    ))}

                    <div className="MB10 FlexCol">
                        <div>Currency</div>
                        {["€", "$"].map((cur) => (
                            <div key={cur}>
                                <input
                                    type="radio"
                                    className="ActionInput P5"
                                    value={cur}
                                    checked={formData.currency === cur}
                                    onChange={(e) => handleInputChange("currency", e.target.value)}
                                />
                                <label className="MR10">{cur === "€" ? "Euro" : "Dollar"}</label>
                            </div>
                        ))}
                    </div>
                </div>
                <div className={"Width50"}>
                    <div>Sites</div>
                    <AsyncSelect
                        className="BusinessFinderListSiteId"
                        cacheOptions
                        defaultOptions
                        value={formData.site}
                        isMulti
                        getOptionLabel={(e) => `[${e.site_id}] ${e.site_url}`}
                        getOptionValue={(e) => e.site_id}
                        loadOptions={loadOptions}
                        onChange={handleSelection}
                    />
                </div>
            </div>

            <div className="popup-footer Gap10 FlexRow JustifyBetween">
                <button type="button" onClick={updateBusinessFinder} className="ActionButton BGreen">
                    {!id ? "Add Business Finder" : "Validate"}
                </button>
                <button className="ActionButton BBlack TWhite" onClick={props.togglePopup}>
                    Close
                </button>
            </div>
        </div>
    );
}

export default BusinessFinderSetup;