import { useState, useEffect } from "react";
import axios from "axios";
import { ROOT_DOMAIN_API } from "../../Constant";
import "./TextMailList.css";
import TextMailRow from "./TextMailRow/TextMailRow";

function TextMailList(props) {
    const [textMailList, setTextMailList] = useState([]);

    useEffect(() => {
        (async () => {
            try {
                const resp = await axios(ROOT_DOMAIN_API + "/manage/v1/bo_moderator/bo_textMailList");
                const obj = await resp.data;
                if (obj.status && obj.results) {
                    setTextMailList(obj.results);
                }
            } catch (error) {
                console.error(error);
            }
        })();
    }, []);

    const updateTextMailStatus = (id, status) => {
        (async () => {
            const resp = await axios.post(ROOT_DOMAIN_API + '/manage/v1/bo_moderator/bo_updateTextMailStatus',
                { id: id, status: status });
            const obj = await resp.data;
            if (obj.status) {
                setTextMailList(obj.results);
            }
        })();
    }

    return (
        <div className={"TextMailList GridCol1-13 ShadowContainer P25 BWhite Width100 BoxSizeBorder " + props.childClass}>
            <table className='Width100'>
                <thead>
                    <tr className="BRed TWhite">
                        <th className='P5'>ID</th>
                        <th className='P5'>Libelle</th>
                        <th className='P5'>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {textMailList.map((row, key) => (
                        <TextMailRow
                            key={key}
                            id={row.id}
                            libelle={row.libelle}
                            status={row.status}
                            updateTextMailStatus={updateTextMailStatus}
                        />
                    ))}
                </tbody>
            </table>
        </div>
    );
}

export default TextMailList;